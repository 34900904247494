const jfSummaryRowItem = () => import(/* webpackChunkName: "jfSummaryRowItem" */'./components/JfSummaryRowComponent/JfSummaryRowItemComponent/index.vue');
const jfSummaryRow = () => import(/* webpackChunkName: "jfSummaryRow" */'./components/JfSummaryRowComponent/index.vue');
const jfDivider = () => import(/* webpackChunkName: "jfDivider" */'./components/JfDividerComponent/index.vue');
const jfTreeIndentation = () => import(/* webpackChunkName: "jfTreeIndentation" */'./components/JfTreeIndentationComponent/index.vue');
const jfTreeCompiledCell = () => import(/* webpackChunkName: "jfTreeCompiledCell" */'./components/JfTreeCompiledCellComponent/index.vue');
const jfTreeItem = () => import(/* webpackChunkName: "jfTreeItem" */'./components/JfTreeItemComponent/index.vue');
const jfTree = () => import(/* webpackChunkName: "jfTree" */'./components/JfTreeComponent/index.vue');
const jfTableTop = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableTopComponent/index.vue');
const jfTableCompiledCell = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableCompiledCellComponent/index.vue');
const jfTableViewBatchActions = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableViewBatchActionsComponent/index.vue');
const jfTableRow = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableRowComponent/index.vue');
const jfTableView = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableViewComponent/index.vue');
const jfTableViewWrapper = () => import(/* webpackChunkName: "jfTable" */'./components/JfTableViewWrapper/index.vue');
const jfFileDrop = () => import(/* webpackChunkName: "jfFileDrop" */'./components/JfFileDropComponent/index.vue');
const jfGraphs = () => import(/* webpackChunkName: "jfGraphs" */'./components/JfGraphsComponent/index.vue');
const jfPendingData = () => import(/* webpackChunkName: "jfPendingData" */'./components/JfPendingDataComponent/index.vue');
const jfTextBox = () => import(/* webpackChunkName: "jfTextBox" */'./components/JfTextBoxComponent/index.vue');
const jfOnOffSwitch = () => import(/* webpackChunkName: "jfOnOffSwitch" */'./components/JfOnOffSwitchComponent/index.vue');
const jfSwitchToggler = () => import(/* webpackChunkName: "jfSwitchToggler" */'./components/JfSwitchTogglerComponent/index.vue');
const jfToggler = () => import(/* webpackChunkName: "jfToggler" */'./components/JfTogglerComponent/index.vue');
const jfDataList = () => import(/* webpackChunkName: "jfDataList" */'./components/JfDataListComponent/index.vue');
const jfVscrollElement = () => import(/* webpackChunkName: "jfTable" */'./components/JfVscrollElementComponent/index.vue');
const jfVscroll = () => import(/* webpackChunkName: "jfTable" */'./components/JfVscrollComponent/index.vue');
const jfContextMenu = () => import(/* webpackChunkName: "jfContextMenu" */'./components/JfContextMenuComponent/index.vue');
const jfDragAndDropTxt = () => import(/* webpackChunkName: "jfDragAndDropTxt" */'./components/JfDragAndDropTxtComponent/index.vue');
const jfRadioButton = () => import(/* webpackChunkName: "jfRadioButton" */'./components/JfRadioButtonComponent/index.vue');
const jfJsTreeWrap = () => import(/* webpackChunkName: "jfJsTreeWrap" */'./components/JfJsTreeWrapComponent/index.vue');
const jfMarkupEditor = () => import(/* webpackChunkName: "jfMarkupEditor" */'./components/JfMarkupEditorComponent/index.vue');
const jfListSelection = () => import(/* webpackChunkName: "jfListSelection" */'./components/JfListSelectionComponent/index.vue');
const jfPasswordStrength = () => import(/* webpackChunkName: "jfPasswordStrength" */'./components/JfPasswordStrengthComponent/index.vue');
const jfMarquee = () => import(/* webpackChunkName: "jfMarquee" */'./components/JfMarqueeComponent/index.vue');
const jfWidgetsLayout = () => import(/* webpackChunkName: "jfWidgetsLayout" */'./components/JfWidgetsLayoutComponent/index.vue');
const jfMultiDropdown = () => import(/* webpackChunkName: "jfMultiDropdown" */'./components/JfMultiDropdownComponent/index.vue');
const jfSidebar = () => import(/* webpackChunkName: "jfSidebar" */'./components/JfSidebarComponent/index.vue');
const jfClipCopy = () => import(/* webpackChunkName: "jfClipCopy" */'./components/JfClipCopyComponent/index.vue');
const jfEnterPress = () => import(/* webpackChunkName: "jfEnterPress" */'./components/JfEnterPressComponent/index.vue');
const jfUiSelect = () => import(/* webpackChunkName: "jfUiSelect" */'./components/JfUiSelectComponent/index.vue');
const jfPanel = () => import(/* webpackChunkName: "jfPanel" */'./components/JfPanelComponent/index.vue');
const jfDrawer = () => import(/* webpackChunkName: "jfDrawer" */'./components/JfDrawerComponent/index.vue');
const jfTab = () => import(/* webpackChunkName: "jfTab" */'./components/JfTabComponent/index.vue');
const jfTabs = () => import(/* webpackChunkName: "jfTabs" */'./components/JfTabsComponent/index.vue');
const jfGridFilter = () => import(/* webpackChunkName: "jfGridFilter" */'./components/JfGridFilterComponent/index.vue');
const jfCodeMirror = () => import(/* webpackChunkName: "jfCodeMirror" */'./components/JfCodeMirrorComponent/index.vue');
const jfField = () => import(/* webpackChunkName: "jfField" */'./components/JfFieldComponent/index.vue');
const jfValidation = () => import(/* webpackChunkName: "jfValidation" */'./components/JfValidationComponent/index.vue');
const jfGridBatchActions = () => import(/* webpackChunkName: "jfGridBatchActions" */'./components/JfGridBatchActionsComponent/index.vue');
const jfGridPagination = () => import(/* webpackChunkName: "jfGridPagination" */'./components/JfGridPaginationComponent/index.vue');
const jfGrid = () => import(/* webpackChunkName: "jfGrid" */'./components/JfGridComponent/index.vue');
const jfSwitch = () => import(/* webpackChunkName: "jfSwitch" */'./components/JfSwitchComponent/index.vue');
const jfCheckbox = () => import(/* webpackChunkName: "jfCheckbox" */'./components/JfCheckboxComponent/index.vue');
const jfListMaker = () => import(/* webpackChunkName: "jfListMaker" */'./components/JfListMakerComponent/index.vue');
const jfHelpTooltip = () => import(/* webpackChunkName: "jfHelpTooltip" */'./components/JfHelpTooltipComponent/index.vue');
const jfActions = () => import(/* webpackChunkName: "jfActions" */'./components/JfActionsComponent/index.vue');
const jfDragDropPagination = () => import(/* webpackChunkName: "jfDragDropPagination" */'./components/JfDragDropPaginationComponent/index.vue');
const jfDragDrop = () => import(/* webpackChunkName: "jfDragDrop" */'./components/JfDragDropComponent/index.vue');
const jfTabularDnd = () => import(/* webpackChunkName: "jfTabularDnd" */'./components/JfTabularDndComponent/index.vue');
const jfWizard = () => import(/* webpackChunkName: "jfWizard" */'./components/JfWizardComponent/index.vue');
const jfWizardElement = () => import(/* webpackChunkName: "jfWizardElement" */'./components/JfWizardElementComponent/index.vue');
const vSelect = () => import(/* webpackChunkName: "vSelect" */'./../node_modules/vue-select/src/components/Select.vue');
const Multiselect = () => import(/* webpackChunkName: "Multiselect" */'./../node_modules/vue-multiselect/src/index.js');
import {VueFactory} from "./services/VueFactory";

export function registerGlobalComponents() {
    const {Vue} = VueFactory.getInstance();

    let components = [
        { component: jfWizardElement, name: 'jf-wizard-element' },
        { component: jfWizard, name: 'jf-wizard'},
        { component: jfTabularDnd, name: 'jf-tabular-dnd' },
        { component: jfDragDrop, name: 'jf-drag-drop' },
        { component: jfDragDropPagination, name: 'jf-drag-drop-pagination' },
        { component: jfActions, name: 'jf-actions' },
        { component: jfHelpTooltip, name: 'jf-help-tooltip' },
        { component: jfListMaker, name: 'jf-list-maker' },
        { component: jfCheckbox, name: 'jf-checkbox' },
        { component: jfSwitch, name: 'jf-switch' },
        { component: jfGrid, name: 'jf-grid' },
        { component: jfGridPagination, name: 'jf-grid-pagination' },
        { component: jfGridBatchActions, name: 'jf-grid-batch-actions' },
        { component: jfValidation, name: 'jf-validation' },
        { component: jfField, name: 'jf-field' },
        { component: jfCodeMirror, name: 'jf-code-mirror' },
        { component: jfGridFilter, name: 'jf-grid-filter' },
        { component: jfTabs, name: 'jf-tabs' },
        { component: jfTab, name: 'jf-tab' },
        { component: jfDrawer, name: 'jf-drawer' },
        { component: jfPanel, name: 'jf-panel' },
        { component: jfUiSelect, name: 'jf-ui-select' },
        { component: jfEnterPress, name: 'jf-enter-press' },
        { component: jfClipCopy, name: 'jf-clip-copy' },
        { component: jfSidebar, name: 'jf-sidebar' },
        { component: jfMultiDropdown, name: 'jf-multi-dropdown' },
        { component: jfWidgetsLayout, name: 'jf-widgets-layout' },
        { component: jfMarquee, name: 'jf-marquee' },
        { component: jfPasswordStrength, name: 'jf-password-strength' },
        { component: jfListSelection, name: 'jf-list-selection' },
        { component: jfMarkupEditor, name: 'jf-markup-editor' },
        { component: jfJsTreeWrap, name: 'jf-js-tree-wrap' },
        { component: jfRadioButton, name: 'jf-radio-button' },
        { component: jfDragAndDropTxt, name: 'jf-drag-and-drop-txt' },
        { component: jfContextMenu, name: 'jf-context-menu' },
        { component: jfVscroll, name: 'jf-vscroll' },
        { component: jfVscrollElement, name: 'jf-vscroll-element' },
        { component: jfDataList, name: 'jf-data-list' },
        { component: jfToggler, name: 'jf-toggler' },
        { component: jfSwitchToggler, name: 'jf-switch-toggler' },
        { component: jfOnOffSwitch, name: 'jf-on-off-switch' },
        { component: jfTextBox, name: 'jf-text-box' },
        { component: jfPendingData, name: 'jf-pending-data' },
        { component: jfGraphs, name: 'jf-graphs' },
        { component: jfFileDrop, name: 'jf-file-drop' },
        { component: jfTableView, name: 'jf-table-view' },
        { component: jfTableViewWrapper, name: 'jf-table-view-wrapper' },
        { component: jfTableRow, name: 'jf-table-row' },
        { component: jfTableViewBatchActions, name: 'jf-table-view-batch-actions' },
        { component: jfTableCompiledCell, name: 'jf-table-compiled-cell' },
        { component: jfTableTop, name: 'jf-table-top' },
        { component: jfTree, name: 'jf-tree' },
        { component: jfTreeItem, name: 'jf-tree-item' },
        { component: jfTreeCompiledCell, name: 'jf-tree-compiled-cell' },
        { component: jfTreeIndentation, name: 'jf-tree-indentation' },
        { component: jfDivider, name: 'jf-divider' },
        { component: jfSummaryRow, name: 'jf-summary-row' },
        { component: jfSummaryRowItem, name: 'jf-summary-row-item' },
        { component: vSelect, name: 'v-select' },
        { component: Multiselect, name: 'multiSelect' },
    ];

    components.forEach(comp => {
        Vue.component(comp.name, comp.component);
    });
}
