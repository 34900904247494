let events = {
    TABS_REFRESH: 'tabs:refresh',
    TABS_URL_CHANGED: 'tabs:url:changed',

    FORM_CLEAR_FIELD_VALIDATION: 'form:clear:field',
    FORM_SUBMITTED: 'form:submitted',

    CLOSE_MODAL: 'modal:close',

    CLOSE_NOTIFICATIONS: 'notifications:close',

    SIDEBAR_SIZE_CHANGE: 'sidebar:size:change',

    RESET_GRID_PAGINATION: 'reset:grid:pagination',

    TABLEVIEW_HIDE_ACTIONS_DROPDOWN: 'tableview:hide:actions:dropdown',

    CONTEXT_MENU_OPEN: 'context:menu:open',

    WIZARD_TAB_CHANGE: 'wizard:tab:change'
};   

export default events;


