export class AngularUrlMatcherFactoryMock {
    constructor() {
    }

    $get() {
        return {
        };
    }
    type() {

    }
}
